export enum AccountSubscriptionType {
  DROPIN_PICKUP = 'Drop in/Pickup',
  TEAMS = 'Teams',
  TOURNAMENTS = 'Tournaments',
  USER = 'User',
}

export enum EventInfoDialogCloseMethod {
  SAVE,
  CANCEL,
  OTHER,
  LEAVE,
  UPDATE,
  PAYMENTS,
}

export enum StripeItemType {
  JOIN_EVENT = 'joinEvent',
  JOIN_TEAM = 'joinTeam',
  FORM_SUBMISSION = 'formSubmission',
  CHANGE_TEAM_SUBSCRIPTION = 'changeTeamSubscription',
  CHANGE_USER_SUBSCRIPTION = 'changeUserSubscription',
  LIFETIME_USER_SUBSCRIPTION = 'lifetimeUserSubscription',
  PRODUCT_PAYMENT = 'productPayment',
}

export enum StripeCreateType {
  NEW = 'NEW',
  LINK = 'LINK',
}
